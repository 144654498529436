<template>
  <div
    class="ml-48 mb-12 bottom-0 absolute bg-white w-64 shadow-2xl rounded-lg overflow-hidden z-50"
  >
    <div class="px-8 py-6">
      <h2 class="text-center text-xl">LABS</h2>
      <p class="text-center text-gray-700 text-sm">
        These are features that are still in development
      </p>
      <div class="pt-6 flex flex-col space-y-4">
        <Toggle
          label="Ivy Events Tool"
          :checked="getExperimentStatus('ivy-events-tool')"
          @value-changed="
            (value) => setExperimentStatus(value, 'ivy-events-tool')
          "
        />
        <Toggle
          label="Create Contact"
          :checked="getExperimentStatus('create-contact')"
          @value-changed="
            (value) => setExperimentStatus(value, 'create-contact')
          "
        />
        <!--<Toggle
          label="Create Fleet"
          :checked="getExperimentStatus('create-fleet')"
          @value-changed="
            (value) => setExperimentStatus(value, 'create-fleet')
          "
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@/components/Toggle.vue";

export default {
  name: "Labs",
  components: {
    Toggle,
  },
  methods: {
    closeLabs: function() {
      this.$emit("close");
    },
    getExperimentStatus: function(experiment) {
      return (
        JSON.parse(window.sessionStorage.getItem(`experiment-` + experiment)) ||
        false
      );
    },
    setExperimentStatus: function(value, experiment) {
      window.sessionStorage.setItem(`experiment-` + experiment, value);
      this.$emit("updated-experiment");
    },
  },
};
</script>

// PAST EXPERIMENT NAMES // company // address-lookup // enquiry-pdf-viewer //
contacts //ivy-activation-tool //published-docs // ivy-events-tool //
create-contact // engineer-access
