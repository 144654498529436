<template>
  <div class="flex items-center justify-center w-full">
    <label
        class="flex items-center cursor-pointer"
        :class="{ 'flex-row-reverse': labelPosition == 'left' }"
    >
      <div class="relative h-6">
        <input v-model="value" type="checkbox" class="hidden" :disabled="disabled"/>
        <div
            class="w-12 h-full bg-gray-300 rounded-full shadow-inner transition duration-300 ease-in-out"
            :class="{ [sliderThemeClass]: value }"
        ></div>
        <div
            class="transform transition duration-300 ease-in-out absolute border w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"
            :class="{ [handleActiveClass]: value }"
        ></div>
      </div>
      <div
          v-if="label"
          :class="[
          labelClasses,
          { 'ml-4': labelPosition == 'right', 'mr-4': labelPosition == 'left' },
        ]"
      >
        {{ label }}
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    checked: {
      type: Boolean,
      required: true,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
      required: false,
    },
    labelPosition: {
      type: String,
      default: "right",
    },
    labelClasses: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      value: false,
      handleActiveClass: "",
      sliderThemeClass: "",
    };
  },

  mounted() {

    this.value = this.checked;
    switch (this.theme) {
      case "danger":
        this.handleThemeClass = "border-red-600";
        this.sliderThemeClass = "bg-red-600";
        break;

      case "success":
        this.handleThemeClass = "border-green-600";
        this.sliderThemeClass = "bg-green-600";
        break;

      default:
        this.handleThemeClass = "border-primary";
        this.sliderThemeClass = "bg-primary";
        break;
    }
    this.handleActiveClass = this.handleActiveClass + " translate-x-6";
  },
  watch: {
    checked: {
      handler(newVal) {
        this.value = newVal;
      },
      immediate: true,
    },
    value() {
      this.$emit("value-changed", this.value);
    },
  },

};
</script>
